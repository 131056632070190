<template>
  <div>
    <div v-if="approved">
      <div class="container">
        <div class="row py-4">
          <div class="d-flex">
            <h1 class="font-weight-light ml-4">Olá, {{ userName }}</h1>
          </div>
        </div>
      </div>
      <app-dashboard v-if="dashboardPermission" />
    </div>
    <div v-if="!approved">
      <app-first-step v-if="isAccountHolder" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import AppDashboard from '@/components/dashboard/Dashboard';
import AppFirstStep from '@/components/activation/FirstStep';

export default {
  name: 'AppWelcome',
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
      permissions: 'account/permissions',
    }),
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    trialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial;
    },
    documentSent() {
      if (!this.whoAmI) return '';
      return this.whoAmI.documentSent;
    },
    approved() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.approved;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    hasCompany() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.holder.company) {
        return false;
      } else {
        return true;
      }
    },
    accountCreatedToday() {
      if (!this.whoAmI) return '';
      let todaysDate = new Date();
      let createdDate = new Date(this.whoAmI.holder.createdAt);
      return (
        createdDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
      );
    },
    dashboardPermissionIndex() {
      return this.permissions.findIndex(
        (x) => x.name === 'paymentAccounts.balance'
      );
    },
    dashboardPermission() {
      if (
        (this.permissions[this.dashboardPermissionIndex] != null &&
          (this.permissions[this.dashboardPermissionIndex].grant ===
            'readonly' ||
            this.permissions[this.dashboardPermissionIndex].grant ===
              'create')) ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    AppDashboard,
    AppFirstStep,
  },
};
</script>

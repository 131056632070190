<template>
  <div>
    <div v-if="!approved">
      <div v-if="accountCreatedToday && !documentSent">
        <div class="header-box">
          <h1 class="text-center mb-0 py-4 font-weight-light">
            Olá {{ firstName }}, que bom que você está aqui!
          </h1>
        </div>
        <div class="container">
          <div class="row justify-content-center mt-5 mb-4">
            <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div>
                <p class="mb-3 font-weight-light h5 text-justify">
                  Sua conta foi criada e está funcionando perfeitamente.
                </p>
                <p class="mb-3 font-weight-light h5 text-justify">
                  Para dar continuidade, é necessário que você confirme algumas
                  informações enviadas no início do cadastro.
                </p>
                <p
                  class="mb-3 font-weight-light h5 text-justify"
                  v-if="!hasCompany"
                >
                  Para isso, envie uma selfie e uma foto do seu documento de
                  identificação - pode ser RG ou CNH.
                </p>
                <p
                  class="mb-3 font-weight-light h5 text-justify"
                  v-if="hasCompany"
                >
                  Para isso, envie uma selfie e uma foto do documento de
                  identificação do titular da empresa - pode ser RG ou CNH.
                </p>
                <p class="font-weight-light h5 text-justify">
                  . Depois disso, analisaremos as informações passadas por você.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="accountCreatedToday && !documentSent">
        <div class="mb-4">
          <h3 class="text-center font-weight-light mb-0">
            Vamos fazer isso agora?
          </h3>
          <p class="text-center font-weight-light h5 mb-0">
            Clique no botão abaixo e siga o passo a passo:
          </p>
        </div>
        <div class="text-center mb-4" v-if="isAndroid || isIOS">
          <div class="mb-3">
            <router-link
              :to="{ name: 'activation', query: { token: this.token } }"
              class="btn btn-orange p-3"
            >
              <big>Enviar Documentos</big>
            </router-link>
          </div>
        </div>
        <div class="text-center mb-4" v-if="!isAndroid && !isIOS">
          <div class="mb-3">
            <router-link
              :to="{ name: 'activation', query: { token: this.token } }"
              class="btn btn-orange p-3"
            >
              <big>Enviar Documentos</big>
            </router-link>
          </div>
          <h4 class="text-center font-weight-light">
            Ou aponte a câmera do celular para a imagem abaixo, caso ele tenha
            leitor QR Code.
          </h4>
          <div>
            <img :src="qrCodeImg" class="rounded" width="300" />
          </div>
        </div>
        <div class="mb-5">
          <p class="text-center font-weight-light h5 mb-0">
            Sua câmera não tem leitor próprio? Não tem problema, você pode
            baixar na loja de aplicativos de sua preferência.
          </p>
        </div>
      </div>
      <div v-if="!accountCreatedToday && !documentSent">
        <div class="header-box">
          <h1 class="text-center mb-0 py-4 font-weight-light" v-if="trialTime">
            Você tem
            {{
              trialTimeLeft === 1
                ? "até amanhã"
                : trialTimeLeft === 0
                ? "até hoje"
                : `${trialTimeLeft} dias`
            }}
            para experimentar o sistema.
          </h1>
          <h1 class="text-center mb-0 py-4 font-weight-light" v-if="!trialTime">
            Seu período de experimentação do sistema expirou.
          </h1>
          <h1 class="text-center mb-0 py-4 font-weight-light">
            Para continuar o cadastro, confirme algumas informações.
          </h1>
        </div>
        <div class="container">
          <div class="row justify-content-center mt-5 mb-4">
            <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div>
                <p
                  class="mb-3 font-weight-light h5 text-justify"
                  v-if="!hasCompany"
                >
                  Confirme algumas informações suas enviando uma selfie e uma
                  foto do seu documento de identificação - pode ser RG ou CNH.
                </p>
                <p
                  class="mb-3 font-weight-light h5 text-justify"
                  v-if="hasCompany"
                >
                  Confirme algumas informações do titular da empresa enviando
                  uma selfie e uma foto do seu documento de identificação - pode
                  ser RG ou CNH.
                </p>

                <p class="mb-3 font-weight-light h5 text-justify">
                  Depois disso, nós analisaremos as informações passadas por
                  você.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!accountCreatedToday && !documentSent">
        <div class="mb-4">
          <h3 class="text-center font-weight-light mb-0">
            Vamos fazer isso agora?
          </h3>
          <p class="text-center font-weight-light h5 mb-0">
            Clique no botão abaixo e siga o passo a passo:
          </p>
        </div>
        <div class="text-center mb-4" v-if="isAndroid || isIOS">
          <div class="mb-3">
            <router-link
              :to="{ name: 'activation', query: { token: this.token } }"
              class="btn btn-orange p-3"
            >
              <big>Enviar Documentos</big>
            </router-link>
          </div>
        </div>
        <div class="text-center mb-4" v-if="!isAndroid && !isIOS">
          <div class="mb-3">
            <router-link
              :to="{ name: 'activation', query: { token: this.token } }"
              class="btn btn-orange p-3"
            >
              <big>Enviar Documentos</big>
            </router-link>
          </div>
          <h4 class="text-center font-weight-light">
            Ou aponte a câmera do celular para a imagem abaixo, caso ele tenha
            leitor QR Code.
          </h4>
          <div>
            <img :src="qrCodeImg" class="rounded" width="300" />
          </div>
        </div>
        <div class="mb-5">
          <p class="text-center font-weight-light h5 mb-0">
            Sua câmera não tem leitor próprio? Não tem problema, você pode
            baixar na loja de aplicativos de sua preferência.
          </p>
        </div>
      </div>
    </div>
    <div v-if="documentSent">
      <div class="header-box">
        <h1 class="text-center mb-0 py-4 font-weight-light">
          Sua conta está em análise!
        </h1>
      </div>
      <div class="container">
        <div class="row justify-content-center mt-5 mb-4">
          <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6">
            <div>
              <p class="mb-3 font-weight-light h5 text-justify">
                {{ firstName }}, estamos analisando as informações enviadas por
                você. Nosso prazo para dar uma resposta é de até 2 dias (úteis).
              </p>
              <p class="font-weight-light h5 text-justify">
                Se você não tiver recebido nenhuma resposta, entre em contato
                com a gente.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="approved">
      <h1 class="text-center mb-5 font-weight-light">
        Sua conta já está ativada e você já pode realizar transações!
      </h1>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AccountApi from '@/services/AccountApi';
import swal from 'sweetalert2';
import Qrious from 'qrious';
import { Presets } from '@/models/Presets';

export default {
  name: 'AppFirstStep',
  async mounted() {
    await this.presets();
    this.openQRCODE();
  },
  data() {
    return {
      preset: new Presets(),
      qrious: null,
      qrCodeImg: '',
    };
  },
  computed: {
    ...mapGetters({
      token: 'account/token',
      whoAmI: 'account/whoAmI',
    }),
    trialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial;
    },
    approved() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.approved;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    documentSent() {
      if (!this.whoAmI) return '';
      return this.whoAmI.documentSent;
    },
    mobilePhone() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    firstName() {
      if (!this.whoAmI) return '';
      let name = this.whoAmI.user.fullName.split(' ');
      return name[0];
    },
    isAndroid: function() {
      return navigator.userAgent.match(/Android/i);
    },
    isIOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    accountCreatedToday() {
      if (!this.whoAmI) return '';
      let todaysDate = new Date();
      let createdDate = new Date(this.whoAmI.holder.createdAt);
      return (
        createdDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
      );
    },
    trialTimeLeft() {
      if (!this.whoAmI) return '';
      let todaysDate = new Date();
      let createdDate = new Date(this.whoAmI.holder.createdAt);
      return 7 - Math.round((todaysDate - createdDate) / (1000 * 60 * 60 * 24));
    },
    hasCompany() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.holder.company) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },
    openQRCODE() {
      const element = this.$refs.qrcode;
      const background = 'white';
      const backgroundAlpha = 0.0;
      const foreground = 'black';
      const foregroundAlpha = 1.0;
      const level = 'L';
      const mime = 'image/png';
      const padding = null;
      const size = 400;
      const value = `${process.env.VUE_APP_APPLICATION_URL}/ativacao?token=${this.token}`;
      this.qrious = new Qrious({
        element,
        background,
        backgroundAlpha,
        foreground,
        foregroundAlpha,
        level,
        mime,
        padding,
        size,
        value,
      });

      this.qrCodeImg = this.qrious.toDataURL();
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    sendSMS() {
      swal({
        type: 'success',
        title: 'Pronto!',
        html: `
        <p>Acabamos de enviar o link para o numero <b>${this.preset.holder.mobile}</b>.</p>
        <p>Pode ser que o SMS demore alguns minutos para chegar.</p>
        <p>Caso você não receba tente novamente mais tarde ou entre em contato com o nosso atendimento através do número.</p>.
        <p class="text-center"><b>(79)3021-0022</b></p>
        <p>ou do email:</p>
        <p>atendimento@paggcerto.com.br</p>`,
      });
    },
  },
};
</script>
